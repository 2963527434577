<template>
  <div>
    <v-btn @click="dialog = true">Item Transfers</v-btn>
    <v-dialog v-model="dialog" persistent width="1400">
      <v-card>
        <v-card-title>Item Transfer Modal</v-card-title>
        <v-card-text>
          <v-text-field
            :label="search"
            v-model="search"
            prepend-icon="mdi-magnify"
          ></v-text-field>
          <h3>All Items Table</h3>
          <v-data-table :search="search" :items="items" :headers="item_headers">
            <template v-slot:item.action="{ item }">
              <v-btn icon @click="open_item_transfer(item)" color="blue"
                ><v-icon>mdi-swap-horizontal</v-icon></v-btn
              >
            </template>
            <template v-slot:item.item_name="{ item }">
              <span
                :class="
                  item.is_vat
                    ? 'green--text font-weight-bold'
                    : 'orange--text font-weight-bold'
                "
                >{{ item.item_name }}</span
              >
            </template>
            <template v-slot:item.is_deleted="{ item }">
              <span v-if="item.is_deleted" class="red--text">Deleted</span>
              <span v-else class="green--text">Active</span>
            </template>
          </v-data-table>
          <h3>Item Transfer Table</h3>
          <v-text-field
            :label="search"
            v-model="search2"
            prepend-icon="mdi-magnify"
          ></v-text-field>
          <v-data-table
            :search="search2"
            :items="item_transfers"
            :headers="item_transfer_headers"
          >
            <template v-slot:item.action="{ item }">
              <v-btn color="red" @click="delete_item_transfer(item)" icon
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">itemTransferModal.vue</p>
        </v-card-actions>
      </v-card>
      <!-- lazy loaded components -->

      <item-transfer-modal-2
        :item="selected_item"
        v-if="show_item_transfer_modal"
        @close="show_item_transfer_modal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  components: { itemTransferModal2: () => import("./itemTransferModal2.vue") },
  props: ["item"],
  data() {
    return {
      selected_item: "",
      show_item_transfer_modal: false,
      search: "",
      search2: "",
      dialog: false,
      loading: false,
      item_headers: [
        { text: "", value: "action", width: "30" },
        { text: "ID", value: "id" },
        { text: "Item Name", value: "item_name" },
        { text: "Item Description", value: "item_description" },
        { text: "Batch", value: "import_batch.batch_description" },
        { text: "Branch", value: "branch.branch_name" },
        { text: "Quantity", value: "quantity" },
        { text: "Is deleted", value: "is_deleted" },
      ],
      item_transfer_headers: [
        { text: "Action", value: "action" },
        { text: "ID", value: "id" },
        { text: "Item Name", value: "item.item_name" },
        { text: "Quantity", value: "quantity" },

        { text: "Transfered", value: "user.full_name" },
        { text: "Transfered from", value: "from_branch" },
        { text: "Transfered to", value: "to_branch" },
        { text: "Transfer Date", value: "transfer_date" },
        { text: "Notes", value: "notes" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      item_transfers: "item_transfer/item_transfers",
      items: "item/items",
      all_items: "item/all_items",
    }),
  },
  methods: {
    del_item(item) {
      let x = window.confirm("Are you sure you want to delete this transfer?");
      if (x) this.delete_item_transfer(item);
      return;
    },
    ...mapActions({
      get_item_transfers: "item_transfer/get_item_transfers",
      delete_item_transfer: "item_transfer/delete_item_transfer",
      get_all_items: "item/get_all_items",
    }),
    open_item_transfer(item) {
      console.log("clicked");
      this.selected_item = item;
      this.show_item_transfer_modal = true;
    },
  },
  created() {
    if (this.item_transfers.length <= 0) this.get_item_transfers();
    if (this.all_items.length <= 0) this.get_all_items();
  },
};
</script>

<style lang="scss" scoped></style>
